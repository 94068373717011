import React from 'react'
import { connect } from 'react-redux'
import { IonButton, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio } from '@ionic/react'
import Layout from '../../components/layout'
import { Title, NormalText, SmallText, Spacer, Sectiontitle } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { forwardTo, checkForDeliveryOption } from '../../lib/utils'
import { setDeliveryAddress, postCodeCheck, setPostCodeData, getNearestLocation, setCommonModal } from '../../store/actions'
import Loading from '../../components/spinner'
import Basket from '../../lib/basket'

// const dummyAddresses = [
// 	{ addressLine1: 'Perina 6', postalCode: '23452' },
// 	{ addressLine1: 'Zikina 9', postalCode: '73452' },
// 	{ addressLine1: 'Neznanog Mafijasa bb', postalCode: '43452' }
// ]

class Delivery extends React.Component {
	state = {
		selectedAddress: null,
		deliveryZoneOption: '',
		deliveryZone: [],
		restaurant: {}
	}
	componentDidMount () {
		checkForDeliveryOption(this.props.deliveryOption, '/delivery')
		const { address_list } = this.props.profile
		Basket.reset()
		if (address_list && address_list.length > 0) {
			const defaultAddress = address_list.find(da => da.default)
			this.setState({ selectedAddress: defaultAddress || address_list[0]})
		}
	}
	componentDidUpdate (prevProps, prevState) {
		checkForDeliveryOption(this.props.deliveryOption, '/delivery')
		if (this.props.checkedCodeData.length > 0 && prevProps.checkedCodeData !== this.props.checkedCodeData) {
			if (this.props.checkedCodeData.length === 1) {
				this.setState({checking: false, postalCodeValid: true, restaurant: this.props.checkedCodeData[0] })
			} else if (this.props.checkedCodeData.length > 1) {
				let deliveryZone = this.props.checkedCodeData
				this.setState({checking: false, postalCodeValid: true, deliveryZone }, () => {
					this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true))
				})
			}
		} else if (prevState.checking && this.props.checkedCodeData.length === 0) {
			this.setState({checking: false, postalCodeValid: false })
		}
	}
	confirmAddress = () => {
		const { selectedAddress } = this.state
		const restaurants =  this.props.restaurants
		const selectedRestaurantId = this.props.checkedCodeData[this.state.deliveryZoneOption].restaurant_id
		if (selectedAddress){
			this.props.dispatch(setDeliveryAddress(selectedAddress))
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))
			Basket.setRestaurant(restaurants.find(restaurant => restaurant.id === selectedRestaurantId))
			forwardTo('/delivery-time')
		}
	}

	check = (value, type) => {
		if (type === 'postcode') {
			this.props.dispatch(postCodeCheck(value))
			this.setState({ initial: false, checking: true })
		} else if (type === 'distance' || type === 'polygon') {
			this.props.dispatch(getNearestLocation(value))
			this.setState({ initial: false, checking: true, restaurant: {} })
		} else {
			this.setState({ initial: true }, () => {
				this.props.dispatch(setPostCodeData({'data': []}))
			})
		}
		this.setState({ initial: false, checking: true })
	}
	changeDeliveryZone = event => this.setState({ deliveryZoneOption: event.detail.value, error: '' })
	render () {
		const { __, profile, isChooseDeliveryModalOpen, checkedCodeData, deliveryRangeType } = this.props
		const { selectedAddress, deliveryZoneOption, deliveryZone } = this.state
		const { address_list } = profile
		const hasAddress = address_list && address_list.length > 0
		const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : ''

		return (
			<Loading transparent>
				<Layout noPadding>
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title>{ __('Start Delivery Order')}</Title>
							{ hasAddress ? (
							<>
							<SmallText>{ __('Select your delivery address')}</SmallText>
							<Spacer/>
							<IonList>
								<IonRadioGroup value={ selectedAddress } onIonChange={(e) => this.setState({ selectedAddress: e.detail.value })}>
								{
									address_list.map((da, index) => {
										return (
											<div key={ 'delivery-address-' + index } className="flex-col-wrapper">
												<div>
													<IonItem lines="none">
														<div tabIndex="-1"></div>
														<IonRadio color="secondary" slot="start" value={ da } />
														<IonLabel>
															<SmallText>{ da.addressLine1 }</SmallText>
															<br /><NormalText color="black">{ da.postalCode }</NormalText>
														</IonLabel>
													</IonItem>
												</div>
												{/*<div className="flex-min">
													<IonButton fill="clear" className="link" color="secondary" onClick={() => {}}>{ __('Edit')}</IonButton>
												</div>*/}
											</div>
										)
									})
								}
								</IonRadioGroup>
							</IonList>
							</>
							) : null }
							<IonButton fill="clear" className="link" color="secondary" onClick={() => { forwardTo('/delivery-address-check')}}>{ __((hasAddress ? 'Or add another' : 'Add') + ' delivery address')}</IonButton>
						</div>
						<div className="flex-min">
							<IonButton disabled={ !selectedAddress } expand="block" color="secondary" onClick={() => this.check(selectedAddress ? selectedAddress.postalCode: '', deliveryRangeType)}>{ __('Continue')}</IonButton>
						</div>
					</div>
					<div
						className="click-collect-pickers-backdrop"
						style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
						onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}>
					</div>
					<div className={ `click-collect-dialog ${animationMenuClass}` }>
						<div className="click-collect-dialog-layout sc-ion-modal-md">
							<div className="click-collect-dialog-header">
								<h3>{__('Choose delivery')}</h3>
							</div>
							<div
								className="click-collect-dialog-closer"
								style={{ position: 'absolute', right: 0, top: 0 }}
								onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
							>
								<ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
							</div>
							<div className="click-collect-dialog-content">
								<IonList lines="none">
									<IonRadioGroup onIonChange={ this.changeDeliveryZone } value={ deliveryZoneOption }>
										{deliveryZone.map((restaurant, i) => {
											const { restaurant_name } = restaurant
											const price = restaurant.delivery_zone.price
											return (
												<IonItem key={ i } lines='full'>
													<div tabIndex="-1"></div>
													<IonRadio
														color="secondary"
														slot="start"
														value={ i }
													/>
													<IonLabel className="ion-text-wrap">
														<Sectiontitle>{ restaurant_name + ' delivery price ' + price}</Sectiontitle>
													</IonLabel>
												</IonItem>
											)
										})
										}
									</IonRadioGroup>
								</IonList>
							</div>
							<div className="click-collect-dialog-action">
								<IonButton expand="block" color="secondary" onClick={() => { this.confirmAddress() } }>
									{ __('Continue')}
								</IonButton>
							</div>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { profile } = state.profile
	const { deliveryOption, checkedCodeData } = state.orders
	const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common
	const { restaurants } = state.restaurants
	return {
		profile,
		deliveryOption,
		deliveryRangeType,
		isChooseDeliveryModalOpen,
		checkedCodeData,
		restaurants
	}
}

export default connect(stateToProps)(withTranslation(Delivery))
