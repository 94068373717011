import React from 'react'
import { withRouter } from 'react-router'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react'
import { starOutline } from 'ionicons/icons'
import Loading from '../../components/spinner'
import { pencilOutline } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer, Sectiontitle } from '../../components/common'
import Basket from '../../lib/basket'
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton'
import { getConfig } from '../../appConfig'
import Modal from '../../components/modal'
import moment from '../../lib/moment'
import Incrementer from '../../components/incrementer'
import { PillGroup } from '../../components/pill'
import { connect } from 'react-redux'
import { setModal, createOrder, setProtectedReferrer, restoreAuth } from '../../store/actions'
import OrderContent from '../../components/orderContent'
import { forwardTo, validateProfileData, goBack, go, isEmptyObject, isWebConfig } from '../../lib/utils'
import ContentHeader from '../../components/contentHeader'
import { ApplyPoints } from '../../screens/applyPoints'
import './index.css'

const { getRestauranName, getOrderTime, getOrderDate, changeItemQuantity, itemsCount, setMobile, getMobile, getAllergen, getItems, getTotal, getTableNumber, getDeliveryOption, getDeliveryAddress ,getPickUpPoint } = Basket

class OrderSummaryRaw extends React.Component {
	constructor(props) {
		super(props)
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile)
		}
		this.state = {
			quantityModal: null,
			allergenModalOpen: false,
			applyPointsModalOpen: false,
			mobile: getMobile() || ''
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.mobile !== prevProps.profile.mobile) {
			this.setState({ mobile: this.props.profile.mobile })
		}
	}

	handleInput = (key, val) => {
		this.setState({ [key]: val })
		setMobile(val)
	}

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2)
		} else {
			goBack()
		}
	}

	handleSubmit = () => {
		const { dispatch, cards } = this.props
		if (getTotal() === 0) {
			dispatch(createOrder())
			return
		}
		if (cards && cards.length >= 1) {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				forwardTo('/checkout')
				setMobile(this.props.profile.mobile )
			} else {
				forwardTo('/contact-details')
			}
		} else {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				setMobile(this.props.profile.mobile )
				forwardTo('/card-add')
			} else {
				forwardTo('/contact-details')
			}
		}
	}

	handleOrderItemClick = (item, index) => {
		this.setState({ quantityModal: { item, index }})
	}

	updateItemQuantity = () => {
		const { item, index } = this.state.quantityModal
		if (this.state.quantityModal) {
			changeItemQuantity(index, item.quantity)
			this.setState({ quantityModal: null })
		}
	}

	onIncrementerUpdate = newQuantity => {
		this.setState({ quantityModal: {
			...this.state.quantityModal,
			item: {
				...this.state.quantityModal,
				quantity: newQuantity
			}
		}})
	}

	formatDayName = (name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase()
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase()
		} else {
			name = getOrderDate()
		}
		return name
	}

	drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
		let dateName = this.formatDayName(moment(getOrderDate(), 'dddd Do MMM').calendar(null, { sameDay: '[Today]', nextDay: '[Tomorrow]' }))
		let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd')
		const deliveryLabel = deliveryOption.label === 'Order for Delivery' ? 'Delivery Order' : deliveryOption.label
		const styledText = { flex: 1 }
		if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
			return (
				<>
					<Title><strong>{ __(deliveryOption.label )}</strong></Title>
					<SmallText>
						{ __('Ordering for')} {__('drop-off')} { __('at')} { getRestauranName()} { dateName }  { __('at')} { getOrderTime() }<br />
					</SmallText>
				</>
			)
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
			return (
				<>
					<Title><strong>{ __(deliveryLabel) }</strong></Title>
					<SmallText className="heading thiner-text">
						{ __('Ordering for')} {__('delivery')} { __('to')} <strong>{deliveryAddress.addressLine1 },</strong> {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */} { dateName } { __('at')} { this.findSelectedSlot(dayInWeek, getOrderTime()) }<br />
					</SmallText>
				</>
			)
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
			return (
				<>
					<Title><strong>{ __(deliveryOption.label) }</strong></Title>
					<SmallText>
						{ __('Ordering at') } { getRestauranName() } { __('for Table') } { getTableNumber() + '.'} { __('Your order will be brought to you as soon as possible.') }
					</SmallText>
				</>
			)
		} else {
			return (
				<>
					<Title><strong>{ __(orderType)}</strong></Title>
					<SmallText className="flex-col-wrapper flex-row-holder">
						{/* { __('Ordering for')} { getOrderDate()} { __('at')} { getOrderTime()}<br /> */}
						{/* { ' ' + __('from')} { getRestauranName()} */}
						<span style={{ marginBottom: 5 }} className="flex-col-wrapper"><strong>{ __('Collection Location') }</strong> <span className="righted thiner-text">{ getRestauranName() }</span></span>
						<span className="flex-col-wrapper"><strong>{ __('Collection Time') }</strong> <span style={ styledText } className="righted thiner-text">{ getOrderDate('D MMMM YYYY')} { __('at') }  { getOrderTime('h:mm a')}</span></span>
					</SmallText>
				</>
			)
		}
	}

	handleApplyModal = flag => {
		const { history, auth } = this.props
		const isAuth = auth.loggedIn
		if (!isAuth) {
			this.props.dispatch(setProtectedReferrer(history.location.path))
			this.props.dispatch(restoreAuth())
			forwardTo('/login')
		} else {
			if (isWebConfig()) {
				this.setState({ applyPointsModalOpen: flag })
			} else {
				forwardTo('/apply-points')
			}
		}
	}

	render() {
		const { __, profile, dispatch, auth } = this.props
		const { quantityModal, allergenModalOpen, applyPointsModalOpen, mobile } = this.state
		const valid = validateProfileData(profile).isValid
		const allergens = getAllergen() || []
		const appliedVouchers = Basket.getAppliedVocuher()
		const labelTitle = Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType()
		const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' }
		const isAuth = auth.loggedIn
		const basketInstance = this.props.basketInstance || Basket
		const appliedPoints = basketInstance.getAppliedPoints()

		return (
			<>
				<div className="absolute-content flex-row-wrapper">
					<div className="scrollable-y checkout">
						{
							Basket.getItems().length > 0 ?
								// this.drawContentHeader(__, Basket.getDeliveryOption(), labelTitle, Basket.getDeliveryAddress())
								<ContentHeader __={ __ } deliveryOption={ getDeliveryOption() } orderType={ labelTitle } deliveryAddress={ getDeliveryAddress() } pickUpPoint={ getPickUpPoint() } />
								: null
						}
						<IonItem className="contact-number" lines="full">
							<div tabIndex="-1"></div>
							<IonLabel { ...contactLabelArrg } className="ion-text-wrap">
								<Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
							</IonLabel>
							{/* <IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
							</IonInput>
							<IonButtons slot='end' className='no-margin'>
								<IonButton color="gray" disabled={ value === '' } fill="clear" size="small" onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}>
									<IonIcon slot="icon-only" size='small' icon={ pencilOutline }/>
								</IonButton>
							</IonButtons> */}
							<IonInput className='mobile-field' onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
								{
									mobile === '' ?
									// <IonButtons slot='end' className='no-margin'>
									// 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
									// 	</IonButton>
									// </IonButtons>
										<IonIcon icon={ pencilOutline } className="contact-number-icon" />
										: null
								}
							</IonInput>
						</IonItem>
						<div className="righted">
							<IonButton fill="clear" className="link" onClick={ () => forwardTo('/order') }><strong>+ { __('Add Items') }</strong></IonButton>
						</div>
						{ itemsCount() > 0 ?
							<OrderContent handleOrderItemClick={ this.handleOrderItemClick.bind(this) }/> :
							<IonItem lines="none">
								<div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>{ __('No items') }</div>
							</IonItem>
						}
						<div className='validate-content'>
							<ValidateButton />
							{!valid && getConfig().appType.hasEmailValidationEnabled && auth && auth.loggedIn ?
								<div className="verified-content">
									<SmallText color='grey'>{__('You can earn, but not redeem points until your account is verified')}</SmallText>
								</div> : null
							}
						</div>
					</div>
					<div className="flex-min">
						{allergens.length > 0 ?
							<IonButton fill="clear" expand="block" className="link underlined" color="secondary" onClick={() => this.setState({ allergenModalOpen: true })}>{ __('View allergen information')}</IonButton> : null
						}
						{auth && auth.loggedIn ?
							<div>
								<IonButton disabled = {itemsCount() > 0 ? false : true || appliedPoints > 0 } className={`redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : ''}`} onClick={ () => {
									if ((itemsCount() === 0 || !valid) && isAuth) {
										dispatch(setModal('isVerfiedModalOpen', true))
									} else {
										forwardTo('/apply-vouchers')
									}}} expand="block" fill="outline" color="dark">
									{appliedVouchers && appliedVouchers.length > 0 ? __('Replace Voucher') : __('Apply Vouchers')}
								</IonButton>
								<IonButton disabled = {itemsCount() > 0 ? false : true || appliedVouchers.length > 0 } className={`redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : ''}`} onClick={() => {
									if ((itemsCount() === 0 || !valid) && isAuth) {
										dispatch(setModal('isVerfiedModalOpen', true))
									} else {
										this.handleApplyModal(true)
									}
								}} expand="block" fill="outline" color="dark">
									<IonIcon slot="start" icon={ starOutline } />
									{ __('Redeem Points') }
								</IonButton>
							</div> : null
						}
						<IonButton disabled={ itemsCount() === 0 } onClick={ this.handleSubmit } color="secondary" expand="block">{ __('Checkout') }</IonButton>
					</div>
				</div>

				<Modal
					cssClass="quantity-modal"
					title={ __('Change quantity') }
					action={ this.updateItemQuantity }
					actionLabel={ __('Change')}
					isOpen={ !!quantityModal }
					onDidDismiss={ () => this.setState({ quantityModal: null }) }>
					{ quantityModal && quantityModal.item ?
						<Incrementer allowNegative={ false } quantity={ quantityModal.item.quantity } onUpdate={ this.onIncrementerUpdate }/>
						: null }
				</Modal>

				<Modal
					cssClass="allergen-modal"
					isOpen={ allergenModalOpen }
					onDidDismiss={() => this.setState({ allergenModalOpen: false })}>
					<div className="absolute-content flex-row-wrapper">
						<div className="scrollable-y">
							<Title className="centered">{ __('Allergen Information')}</Title>
							<Spacer/>
							{ getItems().map((item, index) => {
								let data = allergens.find(allergen => allergen[1].sku === item.item.sku)
								if (data && data.length > 0) {
									return (
										<div key={ 'allergen-' + index }>
											<Sectiontitle className='no-margin'>{ item.quantity }x { item.item.productName }</Sectiontitle>
											<PillGroup items={ data[0].allergens } borderColor="danger"/>
											<Spacer/>
										</div>
									)
								}
								return null
							})}
						</div>
						<div className="flex-min centered">
							<Spacer/>
							<IonButton fill="clear" className="link underlined" color="dark" onClick={() => this.setState({ allergenModalOpen: false })}>{ __('Hide allergen information')}</IonButton>
						</div>
					</div>
				</Modal>
				<Modal
					cssClass="apply-points-modal"
					isOpen={ applyPointsModalOpen }
					onDidDismiss={() => this.handleApplyModal(false)}>
					<ApplyPoints handleApplyModal={ this.handleApplyModal } applyPointsModalOpen={ applyPointsModalOpen } />
				</Modal>
			</>
		)
	}
}

const mapStateToProps = store => {
	const { basketUpdated, deliveryOption } = store.orders
	const { auth } = store.profile
	return {
		basketUpdated,
		profile: store.profile.profile,
		cards: store.orders.cards || [],
		deliveryOption,
		itemAllergens: store.restaurants.itemAllergens,
		auth
	}
}

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)))

class OrderSummaryWrapped extends React.Component {
	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2)
		}
		else if (this.props.location && this.props.location.state && this.props.location.state.skipBackToThePreviousPage) {
			forwardTo('/order')
		} else {
			goBack()
		}
	}
	render () {
		const { __ } = this.props
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Order Summary') } backHandler = { this.backHandler } scrollY={false}>
					<OrderSummary/>
				</Layout>
			</Loading>
		)
	}
}

export default withTranslation(OrderSummaryWrapped)
