import React from 'react'
import { connect } from 'react-redux'
import { IonList, IonItem, IonLabel, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { setDeliveryOption } from '../../store/actions'
import { forwardTo, getSingleDeliveryOption } from '../../lib/utils'
import { getConfig } from '../../appConfig'
import api from '../../lib/api'
import Basket from '../../lib/basket'

import './index.css'

const { delivery } = getConfig()

class DeliveryOptions extends React.Component {
	setDeliveryOption = (delivery) => {
		this.props.dispatch(setDeliveryOption(delivery))
		forwardTo(delivery.id === 'delivery' ? '/delivery' : delivery.id === 'pick-up-point' ? '/pick-up-point' : delivery.id === 'table' ? '/order-to-table' : '/click-and-collect')
	}

	checkForSingleDeliveryOption = () => {
		const singleDeliveryOption = getSingleDeliveryOption()
		if (singleDeliveryOption) {
			this.setDeliveryOption(singleDeliveryOption)
		}
	}

	componentDidMount () {
		const { defaultMenuId } = this.props
		Basket.reset()
		this.checkForSingleDeliveryOption()
		if (defaultMenuId) {
			api.getDefaultMenu(defaultMenuId).then(res => {
				this.props.dispatch({ type: 'SET_RESTAURANT_PROP', key: 'defaultMenu', value: { ...res, menuName: `${res.menuName} Default` }, merge: true })
			})
		}
	}

	componentDidUpdate () {
		this.checkForSingleDeliveryOption()
	}

	render () {
		const { __ } = this.props
		return (
			<Layout hideSecondToolbar={ true } color="transparent" noPadding={ true }>
				<IonCard className="delivery-options-card">
					<IonCardHeader>
						<IonCardTitle className="ion-text-center" color="primary">{ __('Start New Order')}</IonCardTitle>
					</IonCardHeader>

					<IonCardContent className="delivery-options-menu">
						<IonList>
							{ delivery.map(((d, index) => (
								<IonItem key={ 'delivery-option-' + index } onClick={() => this.setDeliveryOption(d)} lines="none">
									<div tabIndex="-1"></div>
									<IonLabel>{ d.label }</IonLabel>
								</IonItem>
							)))}
						</IonList>
					</IonCardContent>
				</IonCard>
			</Layout>
		)
	}
}

const stateToProps = (state) => ({
	deliveryOption: state.orders.deliveryOption,
	defaultMenuId: state.common.defaultMenuId
})

export default connect(stateToProps)(withTranslation(DeliveryOptions))
