import React from 'react'
import { connect } from 'react-redux'
import { IonCard, IonCardContent, IonIcon, IonItem, IonInput, IonButton, IonSpinner, IonList, IonRadioGroup, IonLabel, IonRadio  } from '@ionic/react'
import { closeCircle, checkmarkCircle } from 'ionicons/icons'
import Layout from '../../components/layout'
import { Title, StrongText, NormalText, SmallText, Spacer, FlexSpacer, Sectiontitle } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { forwardTo, forwardToDeliveryOption, sprintf, isEmptyObject } from '../../lib/utils'
import { setDeliveryAddress, postCodeCheck, setPostCodeData, getNearestLocation, setCommonModal } from '../../store/actions'
import Basket from '../../lib/basket'

import '../clickAndCollect/index.css'
import './index.css'

// const dummyValidAddressFromPostalCode = {
// 	addressLine1: 'Wilton Road'
// }

class DeliveryAddressCheck extends React.Component {
	state = {
		initial: true,
		checking: false,
		postalCode: '',
		postalCodeValid: false,
		deliveryZone: [],
		deliveryZoneOption: '',
		deliveryPrice: '',
		restaurant: {},
		validPostCode: false
	}

	checkDelivery = () => {
		const { deliveryOption } = this.props
		if (!deliveryOption) {
			forwardToDeliveryOption()
		}
	}

	componentDidMount () {
		this.checkDelivery()
	}

	componentDidUpdate (prevProps, prevState) {
		this.checkDelivery()
		if (this.props.checkedCodeData.length > 0 && prevProps.checkedCodeData !== this.props.checkedCodeData) {
			if (this.props.checkedCodeData.length === 1) {
				this.setState({checking: false, postalCodeValid: true, restaurant: this.props.checkedCodeData[0], deliveryPrice:  this.props.checkedCodeData[0].delivery_zone.price })
			} else if (this.props.checkedCodeData.length > 1) {
				let deliveryZone = this.props.checkedCodeData
				this.setState({checking: false, postalCodeValid: true, deliveryZone }, () => {
					this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true))
				})
			}
		} else if (prevState.checking && this.props.checkedCodeData.length === 0) {
			this.setState({checking: false, postalCodeValid: false })
		}
	}

	checkPostCode = (value) => {
		let reg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
		let postalCodeValid = reg.test(value)
		if (postalCodeValid) {
			this.setState({ postalCodeValid,  postalCode: value, initial: false })
		} else {
			this.setState({ postalCodeValid,  postalCode: value, initial: false }, () => {
				this.props.dispatch(setPostCodeData({'data': []}))
			})
		}
	}

	setPostalCode = (e) => {
		this.checkPostCode(e.target.value)
	}

	check = (value, type) => {
		if (type === 'postcode') {
			this.props.dispatch(postCodeCheck(value))
			this.setState({ initial: false, checking: true })
		} else if (type === 'distance' || type === 'polygon') {
			this.props.dispatch(getNearestLocation(value))
			this.setState({ initial: false, checking: true, restaurant: {}, deliveryPrice: '' })
		} else {
			this.setState({ initial: true }, () => {
				this.props.dispatch(setPostCodeData({'data': []}))
			})
		}
		this.setState({ initial: false, checking: true })
	}

	saveAndContinue = () => {
		const { postalCode, restaurant } = this.state
		const { restaurants } = this.props
		this.props.dispatch(setDeliveryAddress({ postalCode }))
		Basket.setRestaurant(restaurants.find(res => res.id === restaurant.restaurant_id))
		forwardTo('/delivery-address-add')
	}

	restaurantName = (restaurant) => {
		if (restaurant && restaurant['restaurant_id']) {
			return restaurant.restaurant_name
		}
		return ''
	}

	restaurantAddress = (restaurant) => {
		if (restaurant && restaurant['restaurant_id']) {
			return restaurant.restaurant_address
		}
		return ''
	}

	changeDeliveryZone = event => this.setState({ deliveryZoneOption: event.detail.value, error: '' })

	setDeliveryPrice = () => {
		let option = this.state.deliveryZoneOption
		let deliveryPrice = this.props.checkedCodeData[option].delivery_zone.price
		this.setState({ deliveryPrice, restaurant:  this.props.checkedCodeData[option] }, () => {
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))
		})
	}
	render () {
		const { __, deliveryOption, profile, checkedCodeData, isChooseDeliveryModalOpen, deliveryRangeType } = this.props
		const { initial, checking, postalCode, postalCodeValid, deliveryZone, deliveryZoneOption, deliveryPrice, restaurant } = this.state
		const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : ''
		return (
		<Layout headerTitle={ __(deliveryOption ? deliveryOption.label : '')} noPadding color="transparent">
			<div className="absolute-content restaurant-gradient"></div>
			<div className="delivery-option-label"><Title>{ deliveryOption ? deliveryOption.label : '' }</Title></div>
			<IonCard color="white" class="restaurant-card">
				<IonCardContent>
					<StrongText>{ __('Check if we deliver to you')}</StrongText>
					<Spacer size="3"/>
					<div className="flex-col-wrapper centered">
						<div className="flex-min"><NormalText>{ __('Postcode')}</NormalText></div>
						<div className="flex-col-wrapper centered">
							<FlexSpacer size="70px"/>
							<div>
								<IonItem style={{ '--min-height': '24px' }}>
									<IonInput className="strong-text" required={ true } value={ postalCode } onIonChange={ this.setPostalCode } type="text" clearInput />
									<IonButton className="no-margin" slot="end" size="small" disabled={ !postalCodeValid } fill="clear" onClick={ () => { this.check(postalCode, deliveryRangeType) }}>{ __('Check')}</IonButton>
								</IonItem>
							</div>
							<FlexSpacer/>
						</div>
						<div className="flex-min">
							{ initial || checking ? null : (
							<IonIcon size="small" color={ postalCodeValid ? 'success' : 'danger' } icon={ postalCodeValid ? checkmarkCircle : closeCircle }/>
							)}
						</div>
					</div>
					<Spacer/>
					<div className="address-checking-box centered">
						{ initial ? null : checking ? (
							<>
								<br/>
								<div><IonSpinner/></div>
								<SmallText>{ __('Checking nearest locations')}</SmallText>
								{/* <div className="flex-col-wrapper">
									<IonButton onClick={() => this.setState({ checking: false, postalCodeValid: true })} fill="clear">(Decide true)</IonButton>
									<div className="flex-min">|</div>
									<IonButton onClick={() => this.setState({ checking: false, postalCodeValid: false })} fill="clear">(Decide false)</IonButton>
								</div> */}
							</>
						) : (
							postalCodeValid && checkedCodeData.length > 0 ?
							isEmptyObject(restaurant) ? null :
							(
								<>
									<SmallText>{ __('Your order will be delivered from:')}</SmallText>
									<br /><SmallText>{ this.restaurantName(restaurant) }, { this.restaurantAddress(restaurant) }</SmallText>
									<br /><SmallText>{ sprintf(__('A delivery %s charge of %s will apply'), 'small', + deliveryPrice)}</SmallText>
								</>
							) : (
								postalCodeValid && checkedCodeData.length === 0 ? null :
								(
									<>
										<Spacer/>
										<SmallText color="danger">{ __('Unfortunately, we don\'t deliver to you yet')}</SmallText>
									</>
								)
							)
						)}
					</div>
					<Spacer/>
					<IonButton disabled={ !postalCodeValid || deliveryPrice === '' } expand="block" color="secondary" onClick={ this.saveAndContinue }>
						{ __('Continue')}
					</IonButton>
				</IonCardContent>
			</IonCard>
			<div
				className="click-collect-pickers-backdrop"
				style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
				onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}>
			</div>
			<div className={ `click-collect-dialog ${animationMenuClass}` }>
				<div className="click-collect-dialog-layout sc-ion-modal-md">
					<div className="click-collect-dialog-header">
						<h3>{__('Choose delivery')}</h3>
					</div>
					<div
						className="click-collect-dialog-closer"
						style={{ position: 'absolute', right: 0, top: 0 }}
						onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
					>
						<ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
					</div>
					<div className="click-collect-dialog-content">
						<IonList lines="none">
							<IonRadioGroup onIonChange={ this.changeDeliveryZone } value={ deliveryZoneOption }>
								{deliveryZone.map((restaurant, i) => {
									const { restaurant_name } = restaurant
									const price = restaurant.delivery_zone.price
									return (
										<IonItem key={ i } lines='full'>
											<div tabIndex="-1"></div>
											<IonLabel className="ion-text-wrap">
												<Sectiontitle>{ restaurant_name + ' delivery price ' + price}</Sectiontitle>
											</IonLabel>
											<IonRadio
												color="secondary"
												slot="start"
												value={ i }
											/>
										</IonItem>
									)
								})
								}
							</IonRadioGroup>
						</IonList>
					</div>
					<div className="click-collect-dialog-action">
						<IonButton expand="block" color="secondary" onClick={() => { this.setDeliveryPrice() } }>
							{ __('Continue')}
						</IonButton>
					</div>
				</div>
			</div>
		</Layout>
		)
	}
}

const stateToProps = state => {
	const { profile } = state.profile
	const { deliveryOption } = state.orders
	const { checkedCodeData } = state.orders
	const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common
	const { restaurants } = state.restaurants
	return {
		profile,
		deliveryOption,
		checkedCodeData,
		deliveryRangeType,
		isChooseDeliveryModalOpen,
		restaurants
	}
}

export default connect(stateToProps)(withTranslation(DeliveryAddressCheck))
