import React from 'react'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer, NormalText } from '../../components/common'
import { IonRow, IonCol, IonButton, IonAlert, IonGrid } from '@ionic/react'
// import Box, { BoxHeader } from '../../components/box'
import OrderContent from '../../components/orderContent'
import { createNewBasketInstance } from '../../lib/basket'
import Basket from '../../lib/basket'
import moment from '../../lib/moment'
import { forwardTo } from '../../lib/utils'
import { connect } from 'react-redux'
import { getConfig } from '../../appConfig'
import AmountPaid from '../../components/amountPaid'
import './index.css'

class HistoryDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isCancel: false,
			orderId: null,
			restaurantId: null
		}
	}

	componentDidMount() {
		const { location } = this.props
		const orderArr = location?.state?.order
		const now = moment()
		let cutoffTime = orderArr ? orderArr.cutoff_time : now
		let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss')
		const utcOffset = cutoffTimeMoment.utcOffset()
		cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset)
		if (now.isBefore(cutoffTimeMoment)) {
			if (orderArr.status !== 'REFUNDED') {
				this.setState({ isCancel: true, orderId: orderArr.id, restaurantId: orderArr.restaurant_id })
			}
		}
	}


	backHandler = () => {
		this.setState({ orderId: null, isCancel: false, restaurantId: null })
		forwardTo('/dashboard')
		forwardTo('/history', { tab: 'order' })
	}

	formatPaymentString = (str) => {
		let splitedStr = str.split(' ').splice(2).join(' ')
		return splitedStr
	}

	drawContentTitle = (__, deliveryOption, id, orderType) => {
		if (orderType === 'Outpost Drop-Off') {
			return <Title>{ __('Drop Point')} { __('Order')} #{ id }</Title>
		} else if (orderType === 'Delivery') {
			return <Title><strong>{ __('Delivery')} { __('Order')} #{ id }</strong></Title>
		} else {
			return <Title>{ __(orderType)} { __('Order')} #{ id }</Title>
		}
	}

	drawContent = (__, order, orderType, basketInstance) => {
		let time = moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')
		if (time.indexOf('pm') !== -1) {
			time = time.replace(/ pm/g, '\u00A0pm')
		} else if (time.indexOf('PM') !== -1) {
			time = time.replace(/ PM/g, '\u00A0PM')
		}
		if (time.indexOf('am') !== -1) {
			time = time.replace(/ am/g, '\u00A0am')
		} else if (time.indexOf('AM') !== -1) {
			time = time.replace(/ AM/g, '\u00A0AM')
		}

		if (orderType === 'Click & Collect') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Collect from') }:</SmallText></IonCol>
						<IonCol className="self-centered righted"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Collect at') }:</SmallText></IonCol>
						<IonCol className="self-centered righted"><SmallText color="primary" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order placed on') }:</SmallText></IonCol>
						<IonCol className="self-centered righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="self-centered righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
					</IonRow>
				</>
			)
		} else if (orderType === 'Delivery') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Delivery from') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ order.delivery_address.addressLine1 },  { order.delivery_address.place }, { order.delivery_address.postalCode }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Delivery at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order placed on') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					{order && order.amount_paid > 0 ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
						</IonRow>
						: null
					}
					{ order && order.status === 'REFUNDED' ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order Status')}:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{'Refunded'}</SmallText></IonCol>
						</IonRow>
						: ''
					}
				</>
			)
		} else if (orderType === 'Outpost Drop-Off') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Drop-off at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Drop-off Time') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					{order && order.amount_paid > 0 ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
						</IonRow>
						: null
					}
					{ order && order.status === 'REFUNDED' ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order Status')}:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="primary" className="thiner-text">{'Refunded'}</SmallText></IonCol>
						</IonRow>
						: ''
					}
				</>
			)
		} else if (orderType === 'Table') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Ordered at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText>{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Table number') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText>{ order.table_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText>{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText>{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
					</IonRow>
				</>
			)
		}
	}

	render () {
		const { __, location, cards } = this.props
		const order = location.state.order
		const { id } = order
		const basketInstance = createNewBasketInstance()
		basketInstance.recreateOrder(order)
		const orderType = basketInstance.getOrderType()
		const orderTypeTitle = basketInstance.getOrderType() === 'Click & Collect' ? 'Collection' : basketInstance.getOrderType()
		return (
			<Loading>
				<Layout headerTitle={ __('History') } backHandler = { this.backHandler } scrollY={false}>
					<div className="absolute-content history-details">
						<div className="scrollable-y">
							<div className="order-status">{ order && order.status ? order.status : '' }</div>
							<Spacer size={2} />
							<Title>{ this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle) }</Title>
							{/* <SmallText color="gray">{ __(basketInstance.getOrderType()) } { __('Order') }  #{ order.id }</SmallText> */}
							<Spacer size={ 3 }/>
							{/* <Box>
								<BoxHeader>
									<p className="light-text">
										<SmallText tag="strong">{ __('Order Location') }:</SmallText> <SmallText>{ order.restaurant_name }</SmallText><br />
										<SmallText tag="strong">{ __('Order Time') }:</SmallText> <SmallText>{ basketInstance.formatOrderTime() }</SmallText><br />
										<SmallText tag="strong">{ __('Payment Method') }:</SmallText> <SmallText>{ this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __)) }</SmallText>
									</p>
								</BoxHeader> */}
							<IonGrid className="okx-box-header-grid">
								<NormalText className="bold">{ __('Order Details') }</NormalText>
								<>
									{/* <IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Drop-off at') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Order Placed') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ moment(order.created_at).format('ddd DD MMMM YYYY [at] LT') }</SmallText></IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Drop-off Time') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatOrderTime() }</SmallText></IonCol>
									</IonRow>
									<IonRow className="no-border">
										<IonCol size="5"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
										<IonCol className="righted"><SmallText color="primary" className="thiner-text">{ basketInstance.formatPaymentMethod(this.props.cards, __) }</SmallText></IonCol>
									</IonRow>
									<Spacer size={3} /> */}
									{ this.drawContent(__, order, orderType, basketInstance) }
									<Spacer size={3} />
									<NormalText className="bold">{ __('Items Details') }</NormalText>
									<OrderContent basketInstance={ basketInstance } type="orderHistory"/>
									<AmountPaid order={ order } cards={ cards } />
								</>
							</IonGrid>
							{/* </Box> */}
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	const orders = store.orders
	const { cancelOrderModal } = orders
	const { restaurants } = store.restaurants
	let lastOrder = null
	const orderHistory = orders.orderHistory
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0]
	}
	return {
		cards: orders.cards || [],
		lastOrder,
		restaurants: restaurants || [],
		cancelOrderModal
	}
}

export default connect(mapStateToProps)(withTranslation(HistoryDetails))
