import React from 'react'
import QRCode from 'qrcode-react'
import ValidateButton from '../../components/validateButton'
import { withTranslation } from '../../lib/translate'
import { isDefined, validateProfileData } from '../../lib/utils'
import { getConfig } from '../../appConfig'
import { SmallText } from '../../components/common'
import Basket from '../../lib/basket'

const Scan = ({ __, qr_code, profile }) => {
	const valid = validateProfileData(profile).isValid
	const points = 100
	return (
		<>
			<div className="loyalty-content scan padded scrollable-y">
				{/* <h2>{ __('Scan For Loyalty') }</h2> */}
				<div className="signature-wrap">
					<div className="signature">{ profile.first_name + ' ' + profile.last_name }</div>
					<SmallText>{ __('Collect points every time you scan and earn Rewards') }</SmallText>
					<SmallText>{ '(' + points + ' ' + __('points') + ' - ' + __('worth') + ' ' + Basket._calculatePointsAppliedPrice(points, false, true) + ')' }</SmallText>
				</div>
				{ isDefined(qr_code) ?
					<div className="qr-holder">
						<QRCode value={ qr_code } size={ 200 } />
					</div>
					:
					<div className='noQrCode'><h5>{ __('NO QR CODE') }</h5></div>
				}
				<ValidateButton />
				{!valid && getConfig().appType.hasEmailValidationEnabled ?
					<div className="verified-content">
						<SmallText color='grey'>{ __('You can earn, but not redeem points until your account is verified') }</SmallText>
					</div> : null
				}
			</div>
		</>
	)
}

export default withTranslation(Scan)
