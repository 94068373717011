import React from 'react'
import { connect } from 'react-redux'
import { IonButton, IonList, IonItem, IonInput, IonTextarea, IonLabel } from '@ionic/react'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer, FieldError } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import { validateForm, forwardTo } from '../../lib/utils'
import { setDeliveryAddress, addDeliveryAddress } from '../../store/actions'
import Loading from '../../components/spinner'

class DeliveryAddressAdd extends React.Component {
	state = {
		form: {
			addressLine1: this.props.deliveryAddress ? this.props.deliveryAddress.addressLine1 : '',
			addressLine2: this.props.deliveryAddress ? this.props.deliveryAddress.addressLine2 : '',
			place: this.props.deliveryAddress ? this.props.deliveryAddress.place : '',
			postalCode: this.props.deliveryAddress ? this.props.deliveryAddress.postalCode : '',
			driverNotes: null
		},
		formErrors: {}
	}
	formConfig = {
		addressLine1: { required: true },
		place: { required: true },
		postalCode: { required: true }
	}
	handleInput = (key, val) => {
		const form = {
			...this.state.form,
			[key]: val
		}
		this.setState({
			form,
			formErrors: validateForm(this.formConfig, form)
		})
	}
	save = () => {
		this.props.dispatch(addDeliveryAddress(this.state.form))
	}
	componentDidMount () {
		this.setState({
			formErrors: validateForm(this.formConfig, this.state.form)
		})
	}
	render () {
		const { __, profile, deliveryAddress } = this.props
		const { form, formErrors } = this.state
		const formValid = Object.keys(formErrors).length === 0

		const postalCodeReadOnly = deliveryAddress && Object.keys(deliveryAddress).length === 1 && Object.keys(deliveryAddress)[0] === 'postalCode'

		const postalCodeReadOnlyAttr = postalCodeReadOnly ? { readonly: true } : {}

		return (
			<Loading transparent>
				<Layout>
					<div className="flex-row-wrapper absolute-content">
						<div className="flex-min">
							<SmallText>{ __('Your order will be delivered to:')}</SmallText>
							<br /><SmallText>{ profile.first_name + ' ' + profile.last_name }, { !deliveryAddress ? '--' : deliveryAddress.addressLine1 }</SmallText>
							<Spacer/>
							<Title>{ __('Complete Your Address')}</Title>
							<Spacer/>
						</div>
						<div className="scrollable-y">
							<IonList>
								<IonItem lines="none">
									<IonLabel position="floating">{ __('Address Line 1')}</IonLabel>
									<IonInput required={ true } value={ form.addressLine1 } onIonChange={ e => this.handleInput('addressLine1', e.target.value)} type="text" clearInput/>
									{ formErrors.addressLine1 ? <FieldError className="field-error" value={ __(formErrors.addressLine1)} /> : null }
								</IonItem>
								<IonItem lines="none">
									<IonLabel position="floating">{ __('Address Line 2')}</IonLabel>
									<IonInput value={ form.addressLine2 } onIonChange={ e => this.handleInput('addressLine2', e.target.value)} type="text" clearInput/>
								</IonItem>
								<IonItem lines="none">
									<IonLabel position="floating">{ __('Town')}</IonLabel>
									<IonInput required={ true } value={ form.place } onIonChange={ e => this.handleInput('place', e.target.value)} type="text" clearInput/>
									{ formErrors.place ? <FieldError className="field-error" value={ __(formErrors.place)} /> : null }
								</IonItem>
								<IonItem lines="none">
									<IonLabel position="floating">{ __('Postcode')}</IonLabel>
									<IonInput required={ true } { ...postalCodeReadOnlyAttr } value={ form.postalCode } onIonChange={ e => { return postalCodeReadOnly ? null : this.handleInput('postalCode', e.target.value)}} type="text" clearInput/>
									{ formErrors.postalCode ? <FieldError className="field-error" value={ __(formErrors.postalCode)} /> : null }
								</IonItem>
								<IonItem lines="none">
									<IonLabel position="floating">{ __('Driver Notes')}</IonLabel>
									<IonTextarea value={ form.driverNotes } onIonChange={ e => this.handleInput('driverNotes', e.target.value)} type="text" clearInput/>
								</IonItem>
							</IonList>
						</div>
						<div className="flex-min">
							<Spacer/>
							<IonButton disabled={ !formValid } expand="block" color="secondary" onClick={ this.save }>{ __('Save Address & Continue')}</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { profile } = state.profile
	const { deliveryAddress } = state.orders
	return {
		profile,
		deliveryAddress
	}
}

export default connect(stateToProps)(withTranslation(DeliveryAddressAdd))
