import { getEnv } from './lib/env.js'

let appConfig = {
	theme: {
		nav: {
			label: 'Friska'
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: [ '/item-details' ]
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY')
		}
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
	},
	api: {
		baseURL: 'https://friska-dev.herokuapp.com',
		// baseURL: 'https://3bb8205ede8d.ngrok.io', //preda
		// baseURL: 'http://192.168.0.150:8000', //preda
		// baseURL: 'https://hybrid-master.herokuapp.com/',
		wordPressBaseURL: 'https://hybridapp.co.uk'
	},
	general: {
		clientName: 'Friska',
		appExitRoutes: [ '/', '/home', '/dashboard' ],
		authRoutes: [ '/login', '/register', '/reset-password' ],
		isReduxDevToolsOn: true
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
		hasCampaignManager: true,
		referAFriend: true
	},
	delivery: [
		{
			id: 'collection',
			label: 'Click & Collect',
			route: '/click-and-collect'
		},
		{
			id: 'table',
			label: 'Order to Table',
			route: '/order-to-table'
		},
		// {
		// 	id: 'delivery',
		// 	label: 'Order for Delivery',
		// 	route: '/delivery'
		// },
		//{
		//	id: 'pick-up-point',
		//	label: 'Order to Pick Up Point',
		//	route: '/pick-up-point'
		//}
	],
	configS3: {
		imageNamePrefix: 'friska_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
		secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY')
	},
	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
		measurementId: getEnv('FIREBASE_MEASUREMENT_ID')
	},
	payment: 'judopay'
}

export const updateConfig = newConfig => appConfig = newConfig

export const getConfig = () => appConfig

export default appConfig
