import React from 'react'
import moment from '../../lib/moment'
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert } from '@ionic/react'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer, NormalText } from '../../components/common'
import Box, { BoxHeader } from '../../components/box'
import { connect } from 'react-redux'
import { createNewBasketInstance } from '../../lib/basket'
import { forwardTo } from '../../lib/utils'
import OrderContent from '../../components/orderContent'
import Basket from '../../lib/basket'
import { withRouter } from 'react-router'
import AmountPaid from '../../components/amountPaid'
import { getConfig } from '../../appConfig'
import './index.css'

class OrderCompleted extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isCancel: false,
			orderId: null,
			restaurantId: null
		}
	}

	componentDidUpdate (prevProps) {
		if (this.props.lastOrder) {
			if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
				const { lastOrder } = this.props
				const orderArr = lastOrder
				const now = moment()
				let cutoffTime = orderArr ? orderArr.cutoff_time : now
				let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss')
				const utcOffset = cutoffTimeMoment.utcOffset()
				cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset)
				if (now.isBefore(cutoffTimeMoment)) {
					this.setState({ isCancel: true, orderId: orderArr.id, restaurantId: orderArr.restaurant_id })
				}
			}
		}
	}


	drawContentTitle = (__, deliveryOption, id, orderType) => {
		if (orderType === 'Outpost Drop-Off') {
			return <Title>{ __('Drop Point')} { __('Order')} #{ id }</Title>
		} else if (orderType === 'Delivery') {
			return <Title><strong>{ __('Delivery')} { __('Order')} #{ id }</strong></Title>
		} else if (orderType === 'Table') {
			return <Title><strong>{ __('At Table') } { __('Order') } #{ id }</strong></Title>
		} else {
			return <Title>{ __(orderType)} { __('Order')} #{ id }</Title>
		}
	}

	drawContent = (__, order, orderType, basketInstance) => {
		let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __)
		let time = moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')
		if (time.indexOf('pm') !== -1) {
			time = time.replace(/ pm/g, '\u00A0pm')
		} else if (time.indexOf('PM') !== -1) {
			time = time.replace(/ PM/g, '\u00A0PM')
		}
		if (time.indexOf('am') !== -1) {
			time = time.replace(/ am/g, '\u00A0am')
		} else if (time.indexOf('AM') !== -1) {
			time = time.replace(/ AM/g, '\u00A0AM')
		}
		if (orderType === 'Click & Collect') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Collect from') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Collect at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order placed on') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ paymentMetod }</SmallText></IonCol>
					</IonRow>
				</>
			)
		} else if (orderType === 'Delivery') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Delivery from') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ order.delivery_address.addressLine1 },  { order.delivery_address.place }, { order.delivery_address.postalCode }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order placed on') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Delivery at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					{order && order.order_value > 0 ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ paymentMetod }</SmallText></IonCol>
						</IonRow>
						: null
					}
				</>
			)
		} else if (orderType === 'Outpost Drop-Off') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Drop-off at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Drop-off Time') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ basketInstance.formatOrderTime(true) }</SmallText></IonCol>
					</IonRow>
					{order && order.order_value > 0 ?
						<IonRow>
							<IonCol size="5" className="self-centered"><SmallText tag="strong" className="bold">{ __('Payment Method') }:</SmallText></IonCol>
							<IonCol className="righted self-centered"><SmallText color="grey" className="thiner-text">{ paymentMetod }</SmallText></IonCol>
						</IonRow>
						: null
					}
				</>
			)
		} else if (orderType === 'Table') {
			return (
				<>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Ordered at') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey">{ order.restaurant_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Table number') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey">{ order.table_name }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Order Placed') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey">{ time }</SmallText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="5" className="lefted self-centered"><SmallText tag="strong">{ __('Payment Method') }:</SmallText></IonCol>
						<IonCol className="righted self-centered"><SmallText color="grey">{ paymentMetod }</SmallText></IonCol>
					</IonRow>
				</>
			)
		}
	}
	drawOrder = (order) => {
		const { __, lastOrder, cards } = this.props
		const { id } = order
		const basketInstance = createNewBasketInstance()
		basketInstance.recreateOrder(order)
		const orderType = basketInstance.getOrderType()
		const orderTypeTitle = basketInstance.getOrderType() === 'Click & Collect' ? 'Collection' : basketInstance.getOrderType()
		return (
			<>
				<div className="order-status">{ lastOrder && lastOrder.status ? __(lastOrder.status) : '' }</div>
				<Spacer size={2} />
				{ this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle) }
				<Spacer size={ 1 }/>
				<Box>
					<BoxHeader>
						<IonGrid className="okx-box-header-grid" style={{paddingTop: '15px'}}>
							<NormalText className="bold">{ __('Order Details') }</NormalText>
							{ this.drawContent(__, order, orderType, basketInstance) }
						</IonGrid>
					</BoxHeader>
					<Spacer size={ 3 }/>
					<NormalText className="bold">{ __('Items Ordered') }</NormalText>
					<OrderContent basketInstance={ basketInstance } type="orderHistory"/>
					<AmountPaid order={ order } cards={ cards } />
				</Box>
			</>
		)
	}

	noOrder = () =>
		<IonItem lines="none">
			<div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>{ this.props.__('No order') }</div>
		</IonItem>

	backHandler = () => {
		const { location } = this.props
		if (location && location.state && location.state.completedOrder) {
			forwardTo('/dashboard')
			forwardTo('/click-and-collect')
			Basket.reset()
		}
	}

	render () {
		const { __, lastOrder } = this.props
		const order = this.props.order || lastOrder
		return (
			<Loading>
				<Layout headerTitle={ __('Order Completed')} backHandler={ this.backHandler }>
					<div className="absolute-content order-details">
						<div className="scrollable-y">
							{ order ? this.drawOrder(order) : this.noOrder() }
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	const orders = store.orders
	const { cancelOrderModal } = orders
	const { restaurants } = store.restaurants
	let lastOrder = null
	const orderHistory = orders.orderHistory
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0]
	}
	return {
		lastOrder,
		cards: orders.cards || [],
		restaurants: restaurants || [],
		cancelOrderModal
	}
}

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)))
